import React from 'react'
import {
  BlockContainer,
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  H1AsH2style,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  TextAsH1style,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './HeroBlock.styles'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const HeroBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <BlockContainer>
            <InfoContainer>
              <TitleWrapper color="#fff">
                <TextAsH1style>
                  Онлайн <span>•</span> консультации
                  <br /> с психологом
                </TextAsH1style>
              </TitleWrapper>
              <DescriptionWrapper color="#fff">
                <Text.Large semiBold>
                  Заполните анкету — мы подберем специалистов, которые умеют
                  работать с апатией
                </Text.Large>
                <SeoPriceForTitleBlock />
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
            <ImageWrapper>
              <HeroImage alt="Онлайн психолог работающий с травмами" />
            </ImageWrapper>
          </BlockContainer>
          <TextWrapper>
            <TitleWrapper>
              <H1AsH2style>Онлайн психолог работающий с апатией</H1AsH2style>
            </TitleWrapper>
            <Text.Large semiBold>
              Апатия — это состояние, при котором человек испытывает недостаток
              мотивации, интереса или беспокойства по поводу вещей, которые
              обычно вызывают сильные эмоции. В YouTalk доступна
              профессиональная индивидуальная психологическая помощь при апатии
              и других состояниях.
            </Text.Large>
          </TextWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
