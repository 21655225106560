import cardFiveIcon from '../../../../static/img/Apatiya/card-five.svg'
import cardFourIcon from '../../../../static/img/Apatiya/card-four.svg'
import cardOneIcon from '../../../../static/img/Apatiya/card-one.svg'
import cardThreeIcon from '../../../../static/img/Apatiya/card-three.svg'
import cardTwoIcon from '../../../../static/img/Apatiya/card-two.svg'
import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const TitleSection = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 32px;
  max-width: 783px;

  @media (max-width: ${size.lg}) {
    max-width: 600px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 24px;
    max-width: 100%;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const DescriptionWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 36px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const TitleCard = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${size.lg}) {
    gap: 24px;
  }

  @media (max-width: ${size.sm}) {
    gap: 16px;
  }
`

export const Card = styled.div`
  display: flex;
  background: #fff;
  border-radius: 32px;
  padding: 24px;
  gap: 16px;
  align-items: center;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const TextWrapper = styled.div``

export const CardOneImage = styled.img.attrs(() => ({
  src: cardOneIcon
}))``

export const CardTwoImage = styled.img.attrs(() => ({
  src: cardTwoIcon
}))``

export const CardThreeImage = styled.img.attrs(() => ({
  src: cardThreeIcon
}))``

export const CardFourImage = styled.img.attrs(() => ({
  src: cardFourIcon
}))``

export const CardFiveImage = styled.img.attrs(() => ({
  src: cardFiveIcon
}))``

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }
`
