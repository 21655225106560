import React from 'react'
import {
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardThreeImage,
  CardTwoImage
} from './CardsBlock.styles'

export const cardData = [
  {
    title: 'Выявление основных факторов',
    text: 'Первым шагом в лечении апатии является понимание ее коренных причин. Это может включать в себя обсуждение состояния с психологом и психиатром, прохождение опросников, обследований у соматических специалистов и т.д.',
    image: <CardOneImage />
  },
  {
    title: 'Психотерапия',
    text: 'Разговорная терапия, такая как когнитивно-поведенческая терапия (КПТ) или психодинамическая терапия, может быть полезной для работы с факторами, которые подкрепляют апатию. Изучая чувства, мысли, переживания и мотивацию человека, терапия может помочь повысить самосознание, научиться регулировать эмоциональное состояние и выстроить рутину жизни.',
    image: <CardTwoImage />
  },
  {
    title: 'Лекарства',
    text: 'В случаях, когда апатия связана с основным психическим заболеванием, таким как депрессия или шизофрения, для облегчения симптомов могут быть назначены лекарства. В зависимости от конкретного диагноза и симптомов могут использоваться антидепрессанты, стабилизаторы настроения или антипсихотические препараты.',
    image: <CardFiveImage />
  },
  {
    title: 'Изменения образа жизни',
    text: 'Внесение позитивных изменений в образ жизни, таких как нормальное питание, регулярные физические упражнения и научение методам снижения стресса, таким как осознанность или медитация, может помочь улучшить общее самочувствие и бороться с чувством апатии.',
    image: <CardThreeImage />
  },
  {
    title: 'Социальная поддержка',
    text: 'Создание сильной социальной паутинки из друзей, семьи или групп поддержки может дать валидацию, помощь и поддержку тем, кто борется с апатией. Общение с людьми, которые понимают их опыт и сочувствуют ему, может помочь чувствовать себя менее изолированными и более мотивированными к социальным контактам.',
    image: <CardFourImage />
  }
]
