import React from 'react'
import {
  BlockImage,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FindYourSpecialist.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const FindYourSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите психолога для лечения апатии прямо сейчас!
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Апатия — это сложное и многогранное эмоциональное состояние,
                которое может оказывать глубокое влияние на качество жизни
                человека. Однако при правильной поддержке и вмешательстве можно
                преодолеть апатию и вновь обрести чувство цели, смысла и связи с
                миром.
              </Text.Large>
              <Text.Large semiBold>
                Психологи играют решающую роль в этом процессе, предлагая
                оценку, лечение и поддержку, чтобы помочь людям вернуть интерес
                к жизни и охватить весь спектр человеческих эмоций.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <BlockImage alt="Подберите психолога и запишитесь на консультацию прямо сейчас!" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
