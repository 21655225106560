import React from 'react'
import { Button, Text } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  CardContainer,
  Content,
  TitleSection,
  WomanImage,
  Wrapper
} from './StartTheWork.styles'
import { Event as GAEvent } from '../../../components/GA'

export const StartTheWork = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <WomanImage />
          <TitleSection>
            Онлайн консультация психотерапевта при апатии в YouTalk
          </TitleSection>
          <Text.Large semiBold>
            Для этого заполните анкету, укажите свои симптомы и наш алгоритм
            подберет вам наиболее подходящих психологов онлайн из каталога
            специалистов YouTalk.
          </Text.Large>
        </CardContainer>
        <ButtonsWrapper>
          <Button.NewPrimary
            href="/wizard/"
            onClick={GAEvent.openSemeinyiPageFirstScreen}
            size="large"
            type="link"
          >
            Подобрать психолога
          </Button.NewPrimary>
        </ButtonsWrapper>
      </Content>
    </section>
  </Wrapper>
)
