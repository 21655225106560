import React from 'react'
import {
  BlockContainer,
  BlockOneImage,
  BlockTwoImage,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TextAsH3styles,
  TitleWrapper,
  Wrapper
} from './WhatIsApatiya.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const WhatIsApatiya = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>Что такое апатия?</Title.H2>
          </TitleWrapper>
          <BlockContainer>
            <ImageWrapper>
              <BlockOneImage alt="Апатия – это больше, чем просто мимолетное чувство незаинтересованности или лени" />
            </ImageWrapper>
            <InfoContainer>
              <TitleWrapper>
                <TextAsH3styles>
                  Апатия – это больше, чем просто мимолетное чувство
                  незаинтересованности или лени.
                </TextAsH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Она характеризуется всепроникающим чувством безразличия и
                  сниженной способностью взаимодействовать с окружением или
                  эмоциями. Людям, испытывающим апатию, может быть сложно
                  почувствовать и проявить энтузиазм к занятиям, которые им
                  когда-то нравились, или установить связь с другими на
                  эмоциональном уровне. Это эмоциональное онемение может
                  привести к ощущению оторванности от жизни и значительно
                  ухудшить функционирование в различных сферах.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </BlockContainer>
          <BlockContainer>
            <InfoContainer>
              <TitleWrapper>
                <TextAsH3styles>
                  Апатия может проявляться в разных формах: от легкой
                  незаинтересованности до полного отсутствия мотивации и
                  инициативы.
                </TextAsH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Оно может поражать людей любого возраста и происхождения и
                  может быть симптомом многих расстройств, например, депрессии
                  или посттравматического стрессового расстройства. В некоторых
                  случаях апатия может быть временной реакцией на стресс или
                  травму, а в других она может стать хроническим и изнурительным
                  состоянием если не обратиться за профессиональной помощью.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockTwoImage alt="Апатия может проявляться в разных формах" />
            </ImageWrapper>
          </BlockContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
