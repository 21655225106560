import React from 'react'
import {
  BlockContainer,
  BlockFiveImage,
  BlockFourImage,
  BlockOneImage,
  BlockThreeImage,
  BlockTwoImage,
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TextAsH3styles,
  TitleWrapper,
  Wrapper
} from './HelpWithApatiya.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'

export const HelpWithApatiya = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>Психологическая помощь при апатии онлайн</Title.H2>
          </TitleWrapper>

          <BlockContainer>
            <ImageWrapper>
              <BlockOneImage alt="Психологическая помощь — одна из основ лечения апатии" />
            </ImageWrapper>
            <InfoContainer>
              <TitleWrapper>
                <TextAsH3styles>
                  Психологическая помощь — одна из основ лечения апатии
                </TextAsH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Посредством сочетания оценки, вмешательства и поддержки
                  психологи могут предложить рекомендации и стратегии для
                  управления и преодоления симптомов апатии.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </BlockContainer>

          <BlockContainer>
            <InfoContainer>
              <TitleWrapper>
                <TextAsH3styles>
                  Психологи умеют проводить тщательную оценку для определения
                  основных факторов, способствующих апатии
                </TextAsH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Это может включать клинические интервью, психологическое
                  тестирование и сотрудничество с другими медицинскими
                  работниками для исключения медицинских или неврологических
                  причин.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockTwoImage alt="Психологи умеют проводить оценку факторов, способствующих апатии" />
            </ImageWrapper>
          </BlockContainer>

          <BlockContainer>
            <ImageWrapper>
              <BlockThreeImage alt="Персональные планы лечения, адаптированные к уникальным потребностям" />
            </ImageWrapper>
            <InfoContainer>
              <TitleWrapper>
                <TextAsH3styles>
                  На основе результатов оценки психологи могут разработать
                  персональные планы лечения, адаптированные к уникальным
                  потребностям и обстоятельствам каждого человека.
                </TextAsH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Это включает в себя сочетание психотерапии, изменения образа
                  жизни, направленного на устранение основных причин апатии и,
                  при необходимости, направления к психиатру для
                  медикаментозного лечения.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </BlockContainer>

          <BlockContainer>
            <InfoContainer>
              <TitleWrapper>
                <TextAsH3styles>
                  Психологи могут предоставить важное психообразование и
                  информацию об апатии
                </TextAsH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  О ее симптомах и ее потенциальном влиянии на психическое
                  здоровье и функционировании. Предоставляя знания, психологи
                  могут помочь уменьшить стигму и подтолкнуть активное лечение
                  симптомов апатии.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockFourImage alt="Психологи могут предоставить важное психообразование и информацию об апатии" />
            </ImageWrapper>
          </BlockContainer>

          <BlockContainer>
            <ImageWrapper>
              <BlockFiveImage alt="Предоставление людям безопасного и непредвзятого пространства для выражения своих чувств" />
            </ImageWrapper>
            <InfoContainer>
              <TitleWrapper>
                <TextAsH3styles>
                  Предоставление людям безопасного и непредвзятого пространства
                  для выражения своих чувств и опасений может иметь огромную
                  терапевтическую пользу для лечения апатии
                </TextAsH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Психологи могут предложить эмпатическое слушание, валидацию и
                  эмоциональную поддержку, когда люди находятся на пути к
                  выздоровлению от апатии.
                </Text.Large>
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
          </BlockContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
