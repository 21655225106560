import React from 'react'
import {
  Card,
  CardsContainer,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TextWrapper,
  TitleCard,
  TitleSection,
  Wrapper
} from './CardsBlock.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'
import { cardData } from './cardData'

export const CardsBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H2>
                С чем предстоит работать психологу при лечении апатии
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Хотя с апатией может быть сложно справиться, она не является
                непреодолимой. Стратегии лечения варьируются в зависимости от
                основной причины и тяжести апатии. В некоторых случаях
                достаточно психологической помощи, в других потребуется работа с
                психиатром и прием медикаментов.
              </Text.Large>
              <Text.Large semiBold>
                Лучший вариант — записаться на консультацию к психологу или
                психиатру и решать со специалистом какое лечение необходимо в
                вашем случае.{' '}
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <TitleSection>
            Вот несколько шагов, которые могут быть эффективными в борьбе с
            апатией:
          </TitleSection>
          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <TextWrapper>
                  <TitleCard>{card.title}</TitleCard>
                  <Text.Large semiBold>{card.text}</Text.Large>
                </TextWrapper>
              </Card>
            ))}
          </CardsContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
