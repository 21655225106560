import blockOneIcon from '../../../../static/img/Apatiya/block-one-image.svg'
import blockOneIcon1024 from '../../../../static/img/Apatiya/block-one-image-1024.svg'
import blockOneIcon320 from '../../../../static/img/Apatiya/block-one-image-320.svg'
import blockOneIcon414 from '../../../../static/img/Apatiya/block-one-image-414.svg'
import blockOneIcon768 from '../../../../static/img/Apatiya/block-one-image-768.svg'

import blockTwoIcon from '../../../../static/img/Apatiya/block-two-image.svg'
import blockTwoIcon1024 from '../../../../static/img/Apatiya/block-two-image-1024.svg'
import blockTwoIcon320 from '../../../../static/img/Apatiya/block-two-image-320.svg'
import blockTwoIcon414 from '../../../../static/img/Apatiya/block-two-image-414.svg'
import blockTwoIcon768 from '../../../../static/img/Apatiya/block-two-image-768.svg'

import blockThreeIcon from '../../../../static/img/Apatiya/block-three-image.svg'
import blockThreeIcon1024 from '../../../../static/img/Apatiya/block-three-image-1024.svg'
import blockThreeIcon320 from '../../../../static/img/Apatiya/block-three-image-320.svg'
import blockThreeIcon414 from '../../../../static/img/Apatiya/block-three-image-414.svg'
import blockThreeIcon768 from '../../../../static/img/Apatiya/block-three-image-768.svg'

import blockFourIcon from '../../../../static/img/Apatiya/block-four-image.svg'
import blockFourIcon1024 from '../../../../static/img/Apatiya/block-four-image-1024.svg'
import blockFourIcon320 from '../../../../static/img/Apatiya/block-four-image-320.svg'
import blockFourIcon414 from '../../../../static/img/Apatiya/block-four-image-414.svg'
import blockFourIcon768 from '../../../../static/img/Apatiya/block-four-image-768.svg'

import blockFiveIcon from '../../../../static/img/Apatiya/block-five-image.svg'
import blockFiveIcon1024 from '../../../../static/img/Apatiya/block-five-image-1024.svg'
import blockFiveIcon320 from '../../../../static/img/Apatiya/block-five-image-320.svg'
import blockFiveIcon414 from '../../../../static/img/Apatiya/block-five-image-414.svg'
import blockFiveIcon768 from '../../../../static/img/Apatiya/block-five-image-768.svg'

import styled from 'styled-components'
import { Button, Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const TextAsH3styles = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TitleWrapper = styled.div`
  flex: 1;

  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
    margin-bottom: 36px;
    max-width: 682px;

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
    }

    @media (max-width: ${size.sm}) {
      max-width: 100%;
      margin-bottom: 16px;
    }
  }

  ${TextAsH3styles} {
    margin-bottom: 8px;
  }
`

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  margin-bottom: 36px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    margin-bottom: 24px;

    &:nth-child(even) {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const BlockOneImage = styled.img.attrs(() => ({}))`
  content: url(${blockOneIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockOneIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    height: 341px;
    content: url(${blockOneIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockOneIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockOneIcon320});
  }
`

export const BlockTwoImage = styled.img.attrs(() => ({}))`
  content: url(${blockTwoIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockTwoIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    height: 341px;
    content: url(${blockTwoIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockTwoIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockTwoIcon320});
  }
`

export const BlockThreeImage = styled.img.attrs(() => ({}))`
  content: url(${blockThreeIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockThreeIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    height: 341px;
    content: url(${blockThreeIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockThreeIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockThreeIcon320});
  }
`

export const BlockFourImage = styled.img.attrs(() => ({}))`
  content: url(${blockFourIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockFourIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    height: 341px;
    content: url(${blockFourIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockFourIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockFourIcon320});
  }
`

export const BlockFiveImage = styled.img.attrs(() => ({}))`
  content: url(${blockFiveIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockFiveIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    height: 341px;
    content: url(${blockFiveIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockFiveIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockFiveIcon320});
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const InfoContainer = styled.div``

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 16px;

  @media (max-width: ${size.md}) {
    width: 100%;
    display: block;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }
`
